<template>
	<div>
		<div v-if="transactions.length > 0">
			<table class="table transactions encore-background">
				<!-- <tr>
					<th>{{ languageStrings.casinoId }}</th>
					<td>{{ item.casinoId }}</td>
				</tr> -->
				<table class="transactions second-table-container">
					<thead>
						<tr class="header-row">
							<th>{{ languageStrings.fromPlayerCash }}</th>
							<th>{{ languageStrings.initiationDate }}</th>
							<!-- <td>{{ systemCurrencyTool.formatCurrency(item.fromPlayerCashCOC, systemCurrencyTool.displayType.minorOrFull) }}</td> -->
						</tr>
					</thead>
					<tbody v-for="(item, index) in transactions" :class="checkForExpandedRows(index) ? 'selected' : expandedRows.length == 0 ? 'selected' : 'not-selected'" :key="index" @click="openDetails(index)">
						<tr :id="index">
							<td v-if="item.fromPlayerCashCOC >= 0">
								{{
									systemCurrencyTool.formatCurrency(
										item.fromPlayerCashCOC,
										systemCurrencyTool.displayType.minorOrFull
									)
								}}
							</td>
							<td v-else class="negative-value">
								{{
									systemCurrencyTool.formatCurrency(
										item.fromPlayerCashCOC,
										systemCurrencyTool.displayType.minorOrFull
									)
								}}
							</td>
							<td v-if="item.initiationDate" class="nowrap-text">
								{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}
							
							<i v-if="checkForExpandedRows(index)" class="arrow up"></i>
							<i v-else class="arrow down"></i>
						</td>
							<td v-else></td>
						</tr>
						<tr v-if="checkForExpandedRows(index)" class="panel">
							<td colspan="2" class="limit-width">
								<table class="limit-width">
									<thead class="limit-width">
										<th class="curved-border second-table-table-header-colors">{{ languageStrings.status }}</th>
										<th class="second-table-table-header-colors">{{ languageStrings.offerExpirationDate }}</th>
										<th class="second-table-table-header-colors">{{ languageStrings.provider }}</th>
										<!-- <th class="curved-border" style="text-wrap: wrap;">{{ languageStrings.statusAdditionalInfo }}</th> -->
									</thead>
									<td class="expandable-data">{{ item.status }}</td>
									<td v-if="item.offerExpirationDate" class="expandable-data">
										{{
											new Date(item.offerExpirationDate).toLocaleTimeString(
												this.localeString,
												dateOptions
											)
										}}
									</td>
									<td v-else></td>
									<td class="expandable-data">{{ item.provider }}</td>
										<!-- <td>{{ item.statusAdditionalInfo }}</td> -->
						</table>
							</td>
						</tr>
					</tbody>

					<!-- <tr>
					<th>{{ languageStrings.initiationDate }}</th>
					<td v-if="item.initiationDate">{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
					<td v-else></td>
				</tr>
				<tr>
					<th>{{ languageStrings.offerExpirationDate }}</th>
					<td v-if="item.offerExpirationDate">{{ new Date(item.offerExpirationDate).toLocaleTimeString(this.localeString, dateOptions) }}</td>
					<td v-else></td>
				</tr>
				<tr>
					<th>{{ languageStrings.provider }}</th>
					<td>{{ item.provider }}</td>
				</tr>
				<tr>
					<th>{{ languageStrings.statusAdditionalInfo }}</th>
					<td>{{ item.statusAdditionalInfo }}</td>
				</tr> -->
				</table>
			</table>
		</div>
		<h2 v-else>{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script>
import sharedScripts from "../dependencies/sharedScripts";

export default {
	name: "TransactionsTableMobile",
	props: {
		systemCurrencyTool: Object,
		systemSettings: Object,
		transactions: Array,
		languageStrings: Object,
	},
	data() {
		return {
			expandedRows: [],
		};
	},
	methods: {
		openDetails(index) {
			document.getElementById(index).addEventListener("click", this.open(index));
		},

		open(index) {
			sharedScripts.openRowDetails(this.expandedRows, index);
		},

		checkForExpandedRows(index) {
			return this.expandedRows.includes(index);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tr.header-row {
	position: sticky;
	top: 0;
	background-color: #6305C9 !important;
	color: #fff;
	border-bottom: 1px solid rgb(123, 123, 123);
	white-space: break-spaces;
}

h1,
h2,
h3 {
	text-align: center;
}

.transactions {
	position: relative;
	width: 90%;
	margin: 0 auto 30px;
	border-collapse: separate;
	text-align: center;
	/* background-color: #d7d7d7; */
	/* box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%); */
	/* background-color: #d7d7d7; */
	/* box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%); */
	box-sizing: border-box;
	/* border: 1px #000 solid; */
	border-spacing: 0 0.7em;
}

.transactions th {
	background-color: black;
	color: white;
	padding: 10px;
	padding: 10px;
	width: 50%;
}

.transactions tr {
	transition: background-color 0.3s;
}

.transactions tbody {
	background-color: white;
	color: #6305c9;
}

button.btn {
	display: block;
}

td::first-letter {
	text-transform: capitalize;
}

tr td:first-of-type {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

tr td:last-of-type {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
tr {
	padding: 10px;
	font-weight: bold;
}

.expandable-data {
	color: #6305c9;
}

.second-table-container {
	width: 96%;
}

.limit-width {
	max-width: 10px;
}

.panel {
	background-color: white;
	color: #6305c9;
}

.second-table-table-header-colors {
	background-color: white !important;
	color: #6305c9 !important;
}

@media (max-width: 767px) and (min-width: 521px) {
	tbody {
		line-height: 4vh;
	}

	.nowrap-text {
		text-wrap: nowrap;
	}
}
</style>
